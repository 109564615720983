/* eslint-disable max-lines */
import React, { useCallback, useState, useRef, useContext, useEffect } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import PlusSquareOutlined from '@ant-design/icons/PlusSquareOutlined';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { Alert, Button, Dropdown, Tooltip, Typography } from 'antd';
import { ModalWarning, CharCount } from 'components/Presentational';
import { Row, Col, Collapse } from 'components/Shared/Antd';
import Toast from 'components/Shared/Toast';
import { isValid } from 'date-fns';
import format from 'date-fns/format';
import { isEmpty } from 'helpers/lodash';
import { useDidMountEffect } from 'hooks';
import { IEventForm, ILoteForm, ITicketForm } from 'interfaces/event';
import FormContext from 'Pages/Events/Context';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import lotService from 'services/lot';
import { v4 } from 'uuid';

import { ticketLotDefault } from '../../mock';
import AddSomething from '../AddSomething';
import Lot from './Lot';
import * as S from './styles';

function formatNumber(number) {
  const decimalSeparator = ',';
  const fixedNumber = Number(number).toFixed(2);
  const [integerPart, decimalPart] = fixedNumber.split('.');

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const formattedDecimalPart = decimalPart ? decimalSeparator + decimalPart : '';

  return formattedIntegerPart + formattedDecimalPart;
}

const { Panel } = Collapse;

const CharCounter = ({ max, align, index }) => {
  const { control } = useFormContext();
  const ticketName = useWatch({ control: control, name: `ticket.${index}.name` });
  return <CharCount value={ticketName} align={align} max={max} />;
};

const menuItemStyle = { padding: 0, margin: 0 };

interface TicketProps {
  canBeDeleted: boolean;
  index: number;
  onDelete: (index: number, ticket: ITicketForm, showModal: boolean) => void;
  setActiveLots: (keys: string[]) => void;
  activeLots: string[];
  activeTicketForm: string;
  setActiveTicketForm: (id: string) => void;
  ticketIdentificator: string;
  isAddingTicket: boolean;
}

const Ticket = ({
  index,
  onDelete,
  setActiveLots,
  activeLots,
  activeTicketForm,
  setActiveTicketForm,
  ticketIdentificator
}: TicketProps) => {
  const [openModalDelete, setOpenModalDelete] = useState<
    ILoteForm & { modal?: 'disable' | 'delete'; lotIndex: number }
  >(null);

  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext<IEventForm>();

  const { isCreate } = useContext(FormContext);

  const { append, fields, remove } = useFieldArray({
    control,
    name: `ticket.${index}.lot`,
    keyName: 'identificator'
  });
  const isPaidWatch = watch(`ticket.${index}.is_paid`);
  const showOnPageWatch = watch(`ticket.${index}.show_on_page`);
  const isUnlimetdWatch = watch(`ticket.${index}.is_unlimited`);
  const [isFree, setIsFree] = useState<boolean>(!isPaidWatch);

  const isAdding = useRef(false);
  const [isEditingLot, setIsEditingLot] = useState(false);

  const handleAddLot = () => {
    if (!isEmpty(window['formErrors'])) {
      Toast.error('Verifique todos os campos');
      return;
    }
    const ticketLotLength = getValues(`ticket.${index}.lot`).length;
    isAdding.current = true;
    setIsEditingLot(false);
    append({ ...ticketLotDefault, identificator: v4(), name: `Lote ${ticketLotLength + 1}` });
  };

  useDidMountEffect(() => {
    if (isAdding.current) {
      const lastIndex = fields.length - 1;
      const lastLotKey = fields[lastIndex].identificator;
      setActiveLots([lastLotKey]);
      if (getValues(`ticket.${index}.lot.${lastIndex}.is_active`)) {
        trigger(`ticket.${index}.lot.${lastIndex}`);
      }
      isAdding.current = false;
    }
  }, [fields.length]);

  const onTicketOk = () => {
    trigger(`ticket.${index}`);
    const { name, buy_minimum, buy_limit } = controlledTicketFields;
    if (!name || !buy_minimum || !buy_limit) {
      Toast.error('Verifique todos os campos');
      return;
    }

    if (isCreate && index === 0) {
      const lotKey = getValues(`ticket.${index}.lot.0.identificator`);
      setActiveLots([lotKey]);
    }

    setActiveTicketForm('');
    setIsEditingLot(false);
  };

  const onLotOk = (ticketIndex: number, lotIndex: number) => {
    trigger(`ticket.${ticketIndex}.lot.${lotIndex}`);
    if (!isEmpty(window['formErrors']?.['ticket']?.[ticketIndex]?.lot[lotIndex])) {
      Toast.error('Verifique todos os campos');
      return;
    }
    setActiveLots([]);
    setIsEditingLot(false);
  };

  const handleDuplicateLot = (lot: ILoteForm) => {
    const newLot: ILoteForm = { ...lot, identificator: v4(), id: '' };

    append({ ...newLot });
    isAdding.current = true;
    setIsEditingLot(false);
  };

  const handleDeleteLot = (lotIndex: number, lot: ILoteForm) => {
    const modalDisable = !!lot.quantity_sold;
    setOpenModalDelete({ ...lot, lotIndex, modal: modalDisable ? 'disable' : 'delete' });
  };

  const removeLot = useCallback(() => {
    const { id, lotIndex } = openModalDelete;
    if (id) {
      lotService.delete(id).subscribe({
        next: () => {
          remove(lotIndex);
          Toast.show('Lote excluído com sucesso!');
        },
        error: ({ data: response }) => {
          if (response && response?.code === 'ERR_CUSTOM') {
            Toast.error(response.details);
          } else {
            Toast.error('Houve um erro ao excluir o lote');
          }
        }
      });
    } else {
      remove(lotIndex);
    }
    setActiveLots([]);
    return setOpenModalDelete(null);
  }, [openModalDelete, remove, setActiveLots]);

  const disableLot = useCallback(() => {
    setOpenModalDelete(null);
    const { id, lotIndex } = openModalDelete;
    if (id) {
      lotService.desactivate(id).subscribe({
        next: () => {
          setValue(`ticket.${index}.lot.${lotIndex}.dateEnd`, new Date());
          setValue(`ticket.${index}.lot.${lotIndex}.is_active`, 0);
          Toast.show('Lote desativado com sucesso');
        },
        error: ({ data: response }) => {
          if (response && response?.code === 'ERR_CUSTOM') {
            Toast.error(response.details);
          } else {
            Toast.error('Houve um erro ao desativar o lote');
          }
        }
      });
    }
    setActiveLots([]);
  }, [index, openModalDelete, setActiveLots, setValue]);

  const watchTicketFields = watch(`ticket.${index}`);
  const controlledTicketFields = { ...watchTicketFields };
  const watchLotFields = watch(`ticket.${index}.lot`);
  const controlledLotFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchLotFields[index]
    };
  });

  useEffect(() => {
    setIsFree(isPaidWatch ? false : true);
  }, []);

  return (
    <S.Wrapper is_active={!!getValues(`ticket.${index}.is_active`)}>
      <React.Fragment>
        <Grid container item spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              error={!!errors.ticket?.[index]?.name}
              fullWidth
              helperText={errors.ticket?.[index]?.name?.message}
              id='ticket-name-text-field'
              InputLabelProps={{
                shrink: true,
                style: { pointerEvents: 'auto' }
              }}
              label={
                <S.LabelWrapper>
                  <span>Nome do ingresso * </span>
                  <Tooltip
                    placement='top'
                    title={
                      <Typography style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                        Insira um nome para o ingresso
                      </Typography>
                    }
                    color='#6d6d6d'
                  >
                    <S.CustomizedHelpOutlineIcon />
                  </Tooltip>
                </S.LabelWrapper>
              }
              placeholder='Insira um nome para seu ingresso'
              variant='outlined'
              {...register(`ticket.${index}.name`)}
            />
            <CharCounter align='right' max={30} index={index} />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={!!errors.ticket?.[index]?.buy_minimum}
              fullWidth
              helperText={errors.ticket?.[index]?.buy_minimum?.message}
              id='min-buy-text-field'
              InputLabelProps={{
                shrink: true,
                style: { pointerEvents: 'auto' }
              }}
              label={
                <S.LabelWrapper>
                  <span>Min. de compra * </span>
                  <Tooltip
                    placement='top'
                    title={
                      <Typography style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                        Insira uma quantidade mínima para compra dos ingressos
                      </Typography>
                    }
                    color='#6d6d6d'
                  >
                    <S.CustomizedHelpOutlineIcon />
                  </Tooltip>
                </S.LabelWrapper>
              }
              type='number'
              variant='outlined'
              {...register(`ticket.${index}.buy_minimum`)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={!!errors.ticket?.[index]?.buy_limit}
              fullWidth
              helperText={errors.ticket?.[index]?.buy_limit?.message}
              id='max-buy-text-field'
              InputLabelProps={{
                shrink: true,
                style: { pointerEvents: 'auto' }
              }}
              label={
                <S.LabelWrapper>
                  <span>Máx. de compra * </span>
                  <Tooltip
                    placement='top'
                    title={
                      <Typography style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                        Insira uma quantidade máxima para compra dos ingressos
                      </Typography>
                    }
                    color='#6d6d6d'
                  >
                    <S.CustomizedHelpOutlineIcon />
                  </Tooltip>
                </S.LabelWrapper>
              }
              placeholder='Insira uma quantidade máxima'
              type='number'
              variant='outlined'
              {...register(`ticket.${index}.buy_limit`)}
            />
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item xs={12} md={4} lg={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFree}
                    onChange={e => {
                      setIsFree(e.target.checked);
                      setValue(`ticket.${index}.is_paid`, !e.target.checked);
                    }}
                  />
                }
                label='Ingresso será gratuito'
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isUnlimetdWatch} {...register(`ticket.${index}.is_unlimited`)} />}
                label='Ingressos ilimitados'
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={showOnPageWatch} {...register(`ticket.${index}.show_on_page`)} />}
                label='Mostrar na página do evento'
              />
            </FormGroup>
          </Grid>
        </Grid>
        {activeTicketForm === ticketIdentificator && (
          <Grid container item>
            <Grid
              container
              item
              justifyContent='start'
              alignItems='center'
              spacing={1}
              style={{ marginBottom: '16px' }}
            >
              <Grid item>
                <Button type='primary' onClick={onTicketOk}>
                  Salvar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    if (getValues(`ticket.${index}.id`)) {
                      setActiveTicketForm('');
                      return;
                    }
                    onDelete(index, getValues(`ticket.${index}`), false);
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </React.Fragment>

      <>
        <Collapse
          activeKey={activeLots}
          expandIconPosition='end'
          onChange={setActiveLots}
          collapsible='icon'
          bordered={false}
        >
          {controlledLotFields.map((item, lotIndex) => {
            const lotIndexError = window['formErrors']?.['ticket']?.[index]?.['lot']?.[lotIndex];
            return (
              <Panel
                showArrow={false}
                header={
                  <Row justify='start' align='middle'>
                    <Col md={8} xs={24}>
                      <div style={{ display: 'flex', gap: '4px', marginTop: 6 }}>
                        {!!lotIndexError && (
                          <Tooltip
                            title={
                              <Typography style={{ padding: '0', fontSize: '13px', color: 'white' }}>
                                Por favor, preencha e salve todas as informações obrigatórias do lote para continuar.
                                (*)
                              </Typography>
                            }
                            color='#6d6d6d'
                            placement='top'
                          >
                            <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: '16px' }} />
                          </Tooltip>
                        )}
                        <Typography.Text>{item.name}</Typography.Text>
                        {!item.is_active && <Typography.Text strong> (Inativo)</Typography.Text>}
                      </div>
                    </Col>
                    {item && (
                      <>
                        {item.value > 0 && (
                          <Col md={4} xs={24}>
                            <Typography.Text strong>Valor: </Typography.Text>
                            <Typography.Text>R$ {formatNumber(item.value)}</Typography.Text>
                          </Col>
                        )}
                        {item.quantity > 0 && (
                          <Col md={4} xs={12}>
                            <Typography.Text strong>Quant.: </Typography.Text>
                            <Typography.Text>{item.quantity > 9000000 ? 'ilimitado' : item.quantity}</Typography.Text>
                          </Col>
                        )}
                        {isValid(item.dateStart) && isValid(item.dateEnd) && (
                          <Col md={8} xs={24}>
                            <Typography.Text>
                              <Typography.Text strong>Início:</Typography.Text> {format(item.dateStart, 'dd/MM')}
                              <Tooltip title={format(item.dateStart, 'HH:mm')} color='#FFBC00'>
                                <ClockCircleOutlined style={{ marginLeft: '5px' }} />
                              </Tooltip>
                              <ArrowRightOutlined style={{ margin: '0 10px' }} />
                              <Typography.Text strong>Até:</Typography.Text> {format(item.dateEnd, 'dd/MM')}
                              <Tooltip title={format(item.dateEnd, 'HH:mm')} color='#FFBC00'>
                                <ClockCircleOutlined style={{ marginLeft: '5px' }} />
                              </Tooltip>
                            </Typography.Text>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                }
                extra={
                  item.is_active && (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: (
                              <S.MenuButton
                                type='text'
                                onClick={() => {
                                  setActiveLots([item.identificator]);
                                  setIsEditingLot(true);
                                }}
                                block
                              >
                                Editar
                              </S.MenuButton>
                            ),
                            key: '1',
                            style: menuItemStyle
                          },
                          {
                            label: (
                              <S.MenuButton
                                type='text'
                                onClick={() => {
                                  handleDuplicateLot(item);
                                }}
                                block
                              >
                                Duplicar
                              </S.MenuButton>
                            ),
                            key: '2',
                            style: menuItemStyle
                          },
                          {
                            label: (
                              <S.MenuButton
                                type='text'
                                onClick={() => handleDeleteLot(lotIndex, getValues(`ticket.${index}.lot.${lotIndex}`))}
                                disabled={getValues(`ticket.${index}.lot`).length === 1}
                                block
                              >
                                Excluir
                              </S.MenuButton>
                            ),
                            key: '3',
                            danger: true,
                            style: menuItemStyle
                          }
                        ]
                      }}
                      placement='bottomRight'
                    >
                      <Button type='text' icon={<MoreOutlined rotate={90} style={{ fontSize: '25px' }} />} />
                    </Dropdown>
                  )
                }
                key={item.identificator}
              >
                <Lot
                  index={lotIndex}
                  key={item.identificator}
                  onDelete={handleDeleteLot}
                  ticketIndex={index}
                  ticketWillBeFree={isFree}
                  ticketWillBeUnlimited={isUnlimetdWatch}
                  onOk={onLotOk}
                  onCancel={() => {
                    setActiveLots([]);
                  }}
                  isEditing={isEditingLot}
                />
              </Panel>
            );
          })}
        </Collapse>

        {controlledTicketFields.lot.length < 1 && (
          <Grid item xs={12} style={{ marginTop: '16px' }}>
            <Alert message='É necessário adicionar um lote' type='warning' banner />
          </Grid>
        )}

        {!activeLots.length && (
          <Grid item xs={12} style={{ marginTop: '16px' }}>
            <AddSomething customStyle={{ borderColor: '#52C41A' }}>
              <AddSomething.Button
                onClick={handleAddLot}
                type='text'
                icon={<PlusSquareOutlined />}
                style={{ color: '#52C41A' }}
              >
                Adicionar Lote
              </AddSomething.Button>
            </AddSomething>
          </Grid>
        )}
      </>

      {openModalDelete?.modal === 'disable' && (
        <ModalWarning
          content='Atenção: Alguns ingressos deste lote já foram vendidos, por esse motivo o lote não pode ser excluído. Deseja desabilitar?'
          onCancel={() => setOpenModalDelete(null)}
          onContinue={() => disableLot()}
          open={openModalDelete.modal === 'disable'}
          title='Desabilitar lote'
        />
      )}

      {openModalDelete?.modal === 'delete' && (
        <ModalWarning
          content='Atenção: Esta ação não poderá ser desfeita. Deseja excluir este lote definitivamente?'
          onCancel={() => setOpenModalDelete(null)}
          onContinue={() => removeLot()}
          open={openModalDelete.modal === 'delete'}
          title='Deletar lote'
        />
      )}
    </S.Wrapper>
  );
};

export default Ticket;
